var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "2",
        height: "10",
        viewBox: "0 0 2 10",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M0.686781 9.5C0.600115 9.5 0.526448 9.474 0.465781 9.422C0.413781 9.36133 0.387781 9.28767 0.387781 9.201V3.039C0.387781 2.95233 0.413781 2.883 0.465781 2.831C0.526448 2.77033 0.600115 2.74 0.686781 2.74H1.31078C1.39745 2.74 1.46678 2.77033 1.51878 2.831C1.57078 2.883 1.59678 2.95233 1.59678 3.039V9.201C1.59678 9.28767 1.57078 9.36133 1.51878 9.422C1.46678 9.474 1.39745 9.5 1.31078 9.5H0.686781ZM0.582781 1.479C0.496115 1.479 0.422448 1.453 0.361781 1.401C0.309781 1.34033 0.283781 1.26667 0.283781 1.18V0.478C0.283781 0.391333 0.309781 0.321999 0.361781 0.27C0.422448 0.209333 0.496115 0.179 0.582781 0.179H1.40178C1.48845 0.179 1.55778 0.209333 1.60978 0.27C1.67045 0.321999 1.70078 0.391333 1.70078 0.478V1.18C1.70078 1.26667 1.67045 1.34033 1.60978 1.401C1.55778 1.453 1.48845 1.479 1.40178 1.479H0.582781Z",
          fill: "currentColor",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }