<template>
  <div class="parking-block">
    <div class="parking-block__header">
      <picture>
        <source
          :srcset="require('@/assets/images/parking/header.png')"
          media="(min-width: 1366px)"
        />
        <source
          :srcset="require('@/assets/images/parking/header-t.jpg')"
          media="(min-width: 640px)"
        />
        <img
          class="parking-block__banner"
          :src="require('@/assets/images/parking/header-m.jpg')"
        />
      </picture>
    </div>
    <!-- <promo-block
      :images="[
        {
          src: require('@/assets/images/promo.jpg'),
          minWidth: '375px',
        },
        {
          src: require('@/assets/images/promoMob.jpg'),
          minWidth: '0',
        },
      ]"
      title="Акция"
      desc="На 10 машиномест до 31.10.2022"
      button-text="Узнать детали"
      @buttonClick="(e) => buttonClickHandler('Узнать детали')"
    /> -->
    <content-wrapper-block v-if="parkingLoaded" class="parking-block__wrapper">
      <div class="parking-block__container">
        <div class="parking-block__title">
          Доступные места
          <span class="parking-block__count">({{ countItems }})</span>
        </div>
        <div class="parking-block__tabs parking-tabs">
          <ui-tab-bar v-model="current" class="parking-tabs__bar">
            <ui-tab-bar-tab
              v-for="(floor, index) in parkingFloors"
              :key="index"
              :value="floor"
            >
              {{ floor }} этаж
            </ui-tab-bar-tab>
          </ui-tab-bar>
        </div>
      </div>
      <div class="parking-block__container">
        <div class="parking-block__plan">
          <img class="parking-block__background" :src="floorImage" />
          <svg
            version="1.1"
            class="parking-viewer"
            xmlns="http://www.w3.org/2000/svg"
            :viewBox="svgViewBox"
            preserveAspectRatio="xMinYMin"
          >
            <text
              v-for="(place, index) in parkingCurrentPaths"
              :key="'_' + index"
              :x="place.center[0]"
              :y="place.center[1] + 5"
              text-anchor="middle"
              fill="black"
              font-weight="500"
              font-size="15"
            >
              {{ place.number }}
            </text>
            <polygon
              v-for="(place, index) in parkingCurrentPaths"
              :key="index"
              class="parking-viewer__item"
              :points="place.path"
              fill="transparent"
              stroke="#484848"
              @click="showParkingModal(place.number)"
            />
          </svg>
        </div>
        <div class="parking-block__content plans-content">
          <div class="plans-content__table">
            <ui-table
              class="plans-content__aparts apart-type-table"
              :data="parkingItems"
              @click-row="({ number }) => showParkingModal(number)"
            >
              <ui-table-column label="Этаж" prop="_floor_number">
                <template slot-scope="_floor_number">
                  {{ _floor_number.row._floor_number }}
                  <div v-if="_floor_number.row.pair" class="apart-type-table__pair">
                    {{ _floor_number.row.pair._floor_number }}
                  </div>
                </template>
              </ui-table-column>
              <ui-table-column label="Номер" prop="number">
                <template slot-scope="number">
                  {{ number.row.number }}
                  <div v-if="number.row.pair" class="apart-type-table__pair">
                    {{ number.row.pair.number }}
                  </div>
                </template>
              </ui-table-column>
              <ui-table-column label="площадь, м²" prop="layout">
                <ui-table-column label=" " prop="layout" />
                <template slot-scope="layout">
                  {{ layout.row.layout.area }}
                  <div v-if="layout.row.pair" class="apart-type-table__pair">
                    {{ layout.row.pair.layout.area }}
                  </div>
                </template>
              </ui-table-column>
              <ui-table-column label="Цена, ₽" prop="cost">
                <ui-table-column label=" " prop="cost" />
                <template slot-scope="cost">
                  <template v-if="cost.row.properties.new_cost || cost.row.discount">
                    <s class="apart-type-table__full-price">{{ cost.row.cost.toLocaleString("ru") }}</s>
                    <span class="apart-type-table__new-price">
                      {{ (cost.row.properties.new_cost || cost.row.discount_cost).toLocaleString("ru") }}
                    </span>
                  </template>
                  <template v-else>
                    {{ cost.row.cost.toLocaleString("ru") }}
                  </template>
                  <div v-if="cost.row.pair" class="apart-type-table__pair">
                    <template v-if="cost.row.pair.properties.new_cost || cost.row.pair.discount">
                      <s class="apart-type-table__full-price">{{ cost.row.cost.toLocaleString("ru") }}</s>
                      <span class="apart-type-table__new-price">
                        {{ (cost.row.pair.properties.new_cost || cost.row.pair.discount_cost).toLocaleString("ru") }}
                      </span>
                    </template>
                    <template v-else>
                      {{ cost.row.cost.toLocaleString("ru") }}
                    </template>
                  </div>
                </template>
              </ui-table-column>
              <ui-table-column>
                <div class="apart-type-table__actions">
                  <img class="apart-type-table__truck" src="../../assets/images/icons/vector.svg" />
                  <div class="apart-type-table__book">Забронировать</div>
                  <div class="apart-type-table__info" @click.stop>
                    <div class="apart-type-table__info-btn">
                      <icon-info />
                    </div>
                    <span class="apart-type-table__info-text">
                      Цена действительна при покупке созависимого машино-места
                    </span>
                  </div>
                </div>
              </ui-table-column>
            </ui-table>
            <ui-button v-if="page < numPages" outline @click="next(+1)">
              Показать больше
            </ui-button>
          </div>
        </div>
      </div>
    </content-wrapper-block>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import UiTabBar from "@/components/ui/UITabBar";
import UiTabBarTab from "@/components/ui/UITabBarTab";
import UiTable from "@/components/ui/UITable";
import UiTableColumn from "@/components/ui/UITableColumn";
import ContentWrapperBlock from "@/components/blocks/ContentWrapperBlock";
import UiButton from "@/components/ui/UIButton";
import IconInfo from '@/components/icons/IconInfo.vue'
// import PromoBlock from "@/components/blocks/PromoBlock";

export default {
  name: "ParkingBlock",
  components: {
    UiTabBarTab,
    UiTabBar,
    ContentWrapperBlock,
    UiTable,
    UiTableColumn,
    UiButton,
    IconInfo
    // PromoBlock,
  },
  data: () => ({
    id: "parking",
    current: -1,
    sortName: "number",
    sortWay: "desc",
    page: 1,
    perPage: 7,
    name: "",
    phone: "",
    checkbox: true,
    popoverVisible: false,
    carPlace: {
      num: null,
      amount: "",
      area: null,
    },
    infoParking: {
      floor: "",
      number: "",
      area: "",
      price: "",
    },
    isMobile: window.innerWidth < 768 ? "true" : "false",
  }),
  computed: {
    ...mapState({
      parking: (state) => state.parking,
      parkingLoaded: (state) => state.parkingLoaded,
      modalSuccess: (state) => state.modalSuccess,
    }),
    svgViewBox() {
      return (
        "0 0 " +
        this.parking[Number(this.current)].plan_image.width +
        " " +
        this.parking[Number(this.current)].plan_image.height
      );
    },
    floorImage() {
      return this.parking[Number(this.current)].plan_image.source;
    },
    parkingFloors() {
      const floors = [];
      Object.keys(this.parking).forEach((item) => {
        floors.push(Number(item));
      });

      return floors.sort((a, b) => (a < b ? -1 : 1));
    },
    parkingCurrentPaths() {
      const paths = [];
      for (const item of this.parking[this.current].flats) {
        const { area_points: path } = item;
        if (!path) {
          continue;
        }
        if (["available", "available_agent"].includes(item.status)) {
          paths.push({
            path,
            center: this.centroid(item.area_points[0]),
            number: item.number,
          });
        }
      }
      return paths;
    },
    allItems() {
      let out = [];
      Object.keys(this.parking).forEach((item) => {
        const floor = []
        for (const floorItem of this.parking[item].flats) {
          if (!['available', 'available_agent'].includes(floorItem.status)
            || floor.some((item) => item.pair?.number === floorItem.number)) {
              continue
          }
          if (floorItem.properties?.pair_number) {
            const pair = this.parking[item].flats.find((item) => item.number === floorItem.properties.pair_number)
            if (pair) floorItem.pair = { ...pair, _floor_number: item } 
          }
          floorItem._floor_number = item;
          floor.push(floorItem)
        }
        out = [...out, ...floor]
      });
      return out;
    },
    countItems() {
      return this.allItems.length;
    },
    countItemsPerPage() {
      return this.allItems.filter(
        (item) => item._floor_number === this.current.toString()
      ).length;
    },
    parkingItems() {
      return this.allItems
        .filter((item) => item._floor_number === this.current.toString())
        .slice(0, this.page * this.perPage)
        .sort((a, b) => {
          return this.sortWay === "desc"
            ? parseFloat(a[this.sortName]) - parseFloat(b[this.sortName])
            : parseFloat(b[this.sortName]) - parseFloat(a[this.sortName]);
        });
    },
    numPages() {
      return Math.ceil(this.countItemsPerPage / this.perPage);
    },
  },
  watch: {
    current(newVal) {
      if (newVal) {
        this.page = 1;
      }
    },
  },
  created() {
    window.addEventListener("resize", () => {
      this.isMobile = window.innerWidth < 768 ? "true" : "false";
    });
  },
  methods: {
    ...mapMutations({
      setModalSuccess: "SET_MODAL_SUCCESS",
    }),
    buttonClickHandler(title) {
      this.$openPopupWithChat(title);
    },
    centroid(pointsString) {
      const points = [];
      pointsString.split(",").forEach((pair) => {
        points.push(
          pair
            .trim()
            .split(" ")
            .map((xy) => {
              return parseInt(xy);
            })
        );
      });

      let minX, maxX, minY, maxY;
      for (let i = 0; i < points.length; i++) {
        minX = points[i][0] < minX || minX == null ? points[i][0] : minX;
        maxX = points[i][0] > maxX || maxX == null ? points[i][0] : maxX;
        minY = points[i][1] < minY || minY == null ? points[i][1] : minY;
        maxY = points[i][1] > maxY || maxY == null ? points[i][1] : maxY;
      }
      return [(minX + maxX) / 2, (minY + maxY) / 2];
    },
    next(change) {
      this.page = Math.max(1, Math.min(this.numPages, this.page + change));
    },
    showParkingModal(number) {
      this.showParkingDialog(this.allItems.find((item) => item.number === number || item.pair?.number === number))
    },
  },
};
</script>

<style lang="scss">
.parking-block {
  .apart-type-table {
    .ui-table__header {
      margin-bottom: 20px;
    }

    .ui-table__header,
    .ui-table__row {
      display: grid;
      grid-template-columns: repeat(4, minmax(60px, 0.5fr)) 1.2fr;

      @media (min-width: $screen-sm) {
        grid-template-columns: 50px 50px 70px 100px 200px;
      }
    }

    .ui-table__body-cell {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      font-size: 10px;
      margin: 13px 15px;

      @media screen and (min-width: $screen-sm) {
        font-size: 13px;
        margin-left: 8px;
        margin-right: 8px;
      }

      &:nth-child(4) {
        margin-top: 10px;
        margin-bottom: 10px;

        @media (min-width: $screen-sm) {
          margin-top: 5px;
          margin-bottom: 5px;
        }
      }

      &:last-child {
        justify-content: center;
      }
    }

    .ui-table__header-cell {
      display: flex;
      justify-content: center;
      font-size: 10px;
      margin: 13px 0 0;
      line-height: 1;

      @media screen and (min-width: $screen-xs) {
        white-space: nowrap;
      }

      @media screen and (min-width: $screen-md) {
        font-size: 11px;
      }
    }

    .apart-type-table__book {
      display: none;

      @media screen and (min-width: $screen-md) {
        display: inline;
        pointer-events: auto;
        color: #e14313;
        font-size: 13px;
        cursor: pointer;
        margin: auto 0;
      }
    }

    .ui-table__row {
      transition: 0.3s;
      pointer-events: auto;
      cursor: pointer;

      &:hover {
        @media screen and (min-width: $screen-md) {
          box-shadow: 4px 5px 12px rgba(0, 0, 0, 0.1);

          .plans-content__truck {
            @media screen and (min-width: $screen-md) {
              display: none;
            }
          }
        }
      }
    }

    &__pair {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 18px;

      @media (min-width: $screen-sm) {
        margin-top: 28px;
      }
    }

    &__full-price {
      text-align: center;
      font-size: 8px;
      color: #18181b4d;

      @media (min-width: $screen-sm) {
        font-size: 11px;
      }
    }

    &__new-price {
      color: #e14313;
      font-weight: 700;
    }

    &__actions {
      display: flex;
      align-items: center;
      gap: 16px;
    }

    &__truck {
      @media (min-width: $screen-md) {
        display: none;
      }
    }

    &__info {
      position: relative;
    }

    &__info-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      border: 1px solid #18181b;
      color: #18181b;

      @media (min-width: $screen-md) {
        width: 32px;
        height: 32px;
      }

      &:hover {
        border-color: #e14313;
        background: #e14313;
        color: #fff;

        & + .apart-type-table__info-text {
          display: flex;
        }
      }
    }

    &__info-text {
      position: absolute;
      right: calc(100% + 5px);
      bottom: 50%;
      z-index: 2;
      display: none;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      width: 180px;
      height: 68px;
      padding: 10px;
      font-size: 12px;
      font-family: $font-family-rubik;
      line-height: 1.3;
      color: #18181b;
      background: #fff;
      border: 1px solid #e143134d;
      transform: translateY(50%);
      pointer-events: none;

      @media (min-width: $screen-sm) {
        right: calc(100% + 10px);
        width: 280px;
        height: 64px;
        padding: 15px;
        pointer-events: auto;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.parking {
  &-container {
    overflow: auto;
    width: 100%;
    position: relative;
  }

  &-viewer {
    min-width: 700px;
    position: absolute;
    top: 0;
    left: 0;

    @media screen and (min-width: $screen-sm) {
      min-width: auto;
    }

    &__item {
      fill: #c7fd2061;
      stroke: #bbe849;
      cursor: pointer;

      &__item--disable {
        fill: rgba(239, 246, 214, 0.38);
        stroke: #e8e8e8;
        cursor: auto;
      }

      &:hover {
        stroke: #fd5620;
        fill: rgba(253, 86, 32, 0.62);
      }
    }
  }

  &-block {
    &__header {
      position: relative;
      height: 140px;
      background-position: bottom;
      background-repeat: no-repeat;
      background-size: cover;

      @media screen and (min-width: $screen-mini) {
        height: 306px;
      }

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          180deg,
          rgba(35, 31, 32, 0.94) 23.19%,
          rgba(35, 31, 32, 0.61) 34.31%,
          rgba(0, 0, 0, 0) 47.69%
        );
      }
    }

    &__banner {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: bottom;
    }

    &__title {
      line-height: 105%;
      letter-spacing: 0.05em;
      color: #18181b;
      font-size: 14px;
      margin-right: 19px;

      @media screen and (min-width: $screen-mini) {
        font-size: 36px;
        margin-right: 52px;
      }
    }

    &__wrapper {
      padding: 15px;

      @media screen and (min-width: $screen-sm) {
        padding: 50px 15px;
      }
    }

    &__count {
      color: #888;
    }

    &__container {
      display: flex;
      align-items: center;
      margin-bottom: 34px;
      flex-direction: column;

      &:first-child {
        flex-direction: row;
      }

      &:last-child {
        margin-bottom: 0;
      }

      @media screen and (min-width: $screen-md) {
        flex-direction: row;
      }
    }

    &__background {
      width: 100%;
      min-width: 700px;

      @media screen and (min-width: $screen-sm) {
        min-width: auto;
      }
    }

    &__plan {
      overflow: auto;
      position: relative;
      width: 100%;
      margin-bottom: 22px;

      @media screen and (min-width: $screen-md) {
        width: calc(100% - 400px);
        margin-bottom: 0;
      }

      @media screen and (min-width: $screen-lg) {
        width: calc(100% - 468px);
      }
    }
  }

  &-selector {
    margin-bottom: 20px;
  }
}

.plans-content {
  display: flex;
  border-top: 1px solid #dedede;
  width: 100%;
  margin-left: 0;

  @media screen and (min-width: $screen-lg) {
    width: 468px;
  }

  @media screen and (min-width: $screen-md) {
    width: 469px;
    margin-left: 61px;
  }

  @media screen and (min-width: $screen-sm) {
    border-top: unset;
    padding-top: 0;
  }

  &__action {
    background-color: #e14313;
    color: #ffffff;
    padding: 5px 6px 2px 8px;
    right: -11px;
    position: absolute;
    font-family: $font-family-bebas;
    font-weight: 700;
    font-size: 18px;
    line-height: 115%;
  }

  &__aparts {
    margin-bottom: 30px;
  }

  &__table {
    width: 100%;

    @media (min-width: $screen-md) {
      width: 470px;
    }
  }
}

.modal-parking {
  &__body {
    display: flex;
    flex-direction: column;
    padding: 25px 0 0 0;
  }

  &__text {
    display: flex;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 160%;
    letter-spacing: 0.2em;
    color: rgba(255, 255, 255, 0.6);
    margin-bottom: 30px;
    margin-top: 0;
  }

  &__footer {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 160%;
    letter-spacing: 0.01em;
    text-transform: none;
    color: #246cd9;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0;

    span {
      position: relative;
      top: 0;
      left: 20px;
      width: 110px;
    }

    @media screen and (min-width: $screen-md) {
      margin: 0 -85px -56px -85px;

      span {
        left: 8px;
        top: -3px;
      }
    }
  }
}

.callback-form {
  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 22px;
    flex-wrap: wrap;

    &:last-child {
      padding-bottom: 0;
    }
  }

  .col-3 {
    width: 100%;
    margin-right: 0;
    margin-bottom: 10px;

    @media screen and (min-width: $screen-sm) {
      width: calc(50% - 20px);
      margin-right: 10px;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
